
import { defineComponent, ref, onMounted } from 'vue'
import { getStyle } from '@/services/utils'

export default defineComponent({
  name: 'Titles',
  setup() {
    const titles = [
      { label: 'Hero - 24', class: ['hero-24', 'hero'], desc: 'Modal windows titles, Empty screens titles' },
      { label: 'Page Title - 20', class: 'page-title-20', desc: 'Secondary page titles' },
      { label: 'Headline - 18', class: 'headline-18', desc: 'Widget/blocks title' },
      { label: 'Subhead Semi Bold - 16', class: 'subhead-semi-bold-16', desc: 'Can be used as unread chat title' },
      { label: 'Subhead Medium - 16', class: 'subhead-medium-16', desc: 'Dashboard, report subtitle' },
      { label: 'Subhead Regular - 16', class: 'subhead-regular-16', desc: 'Dashboard, report subtitle' },
      { label: 'Body Text Semi Bold - 14', class: 'body-text-semi-bold-14', desc: 'Use to make an accent in the main text (User names, Company names)' },
      { label: 'Body Text Medium - 14', class: 'body-text-medium-14', desc: 'Text fields titles, data table column titles, header items, buttons' },
      { label: 'Body Text Regular - 14', class: 'body-text-regular-14', desc: 'Text fields titles, data table column titles, header items, buttons' },
      { label: 'Body Text Semi Bold - 12', class: 'body-text-semi-bold-12', desc: 'Small text, can be used as help or additional text' },
      { label: 'Body Text Medium - 12', class: 'body-text-medium-12', desc: 'Data table pagination' },
      { label: 'Body Text Regular - 12', class: 'body-text-regular-12', desc: 'Data table pagination' },
      { label: 'CAPTION - 12', class: 'caption-12', desc: 'Caption is used to provide details in situations where content is compact and space is tight', moreDesc: '#6E7191;\n letter-spacing: 0.5px;\n uppercase' },
      { label: 'Text Small - 10', class: 'text-small-10', desc: 'Main Menu Labels' },
      { label: 'helptext Lorem ipsum dolor sit', class: 'helptext', desc: 'Main Menu Labels' },
    ]

    const heading = [
      { label: 'H1 Lorem ipsum dolor', class: 'h1', moreDesc: '#4E4B66' },
      { label: 'H2 Lorem ipsum dolor', class: 'h2', moreDesc: '#4E4B66' },
      { label: 'H3 Lorem ipsum dolor', class: 'h3', moreDesc: '' },
      { label: 'H4 Lorem ipsum dolor', class: 'h4', moreDesc: '' },
      { label: 'H5 Lorem ipsum dolor', class: 'h5', moreDesc: '' },
      { label: 'H6 Lorem ipsum dolor', class: 'h6', moreDesc: '' },
    ]

    const getElement = (el: string) => {
      return document.querySelector(el)
    }

    const isMounted = ref(false)
    onMounted(() => {
      isMounted.value = true
    })

    return {
      titles,
      heading,
      getStyle,
      getElement,
      isMounted,
    }
  },
})
